
import { defineComponent, Ref, ref, onUpdated , onMounted, onBeforeMount, onUnmounted } from "vue";
import { Modal } from "bootstrap";
import Button from "@/components/Button.vue"
import Api from "@/services/Api"

export default defineComponent({
  name: "EditItemModal",

  components: { 
    Button,
  },
  props: {
    closeModal: Function,
    getItens: Function,
    setEmptyItemParam: Function,
    itemParam: Object,
  },

  setup(props) {
    const getUrlParam = (param: string) => (new URLSearchParams(window.location.search)).get(param);

    function brMask(i:any) {
      let v = i.replace(/\D/g,'');
      v = (v/100).toFixed(2) + '';
      v = v.replace(".", ",");
      i = v;
      item.value.valor = i
    }

    // --------------------------------------------------
    const attendanceId: Ref<number | null> = ref(getUrlParam('id') ? Number(getUrlParam('id')) : null);

    const emptyItem = {codItemLoja: '', nome: '', status: '', valor: '', valorPeca: '', valorMo: '', categoria: '',codAgregados: '', campoCheckList: '', desconto: '', tipoItem: '', qtd: null, valorUnitario: '', valorOriginal: ''}
  
    const item: Ref<any> = ref({...props.itemParam});
    const statusItem: string[] = ['oferecido', 'vendido', 'orcar', 'orcado', 'recusado', 'aprovado'];
    const message: Ref<string | null> = ref(null)
    const triedToSave: Ref<boolean> = ref(false)
      
    const setEmptyItem = () => item.value = emptyItem

    const dismiss = () => {

      props.setEmptyItemParam && props.setEmptyItemParam();
      setEmptyItem(); 
      props.getItens && props.getItens();
      props.closeModal && props.closeModal();
      message.value = null;
      triedToSave.value = false

    }

    const requiredFieldsFilled = ():boolean => {

      const { codItemLoja, nome, qtd, status, valor } = item.value

      message.value = null;
      let value = true;

      if(!codItemLoja || !nome || !qtd || !valor) {
        message.value = 'Preencha os campos obrigatórios!'
        value = false
      }

      return value;

    }

    const saveItem = async() => {
      triedToSave.value = true;

      if(!requiredFieldsFilled()) return;

      const { data } = await Api.post('saveAttendanceItem', { ...item.value, idOrcamento: attendanceId.value })

      dismiss()
    }

    const deleteItem = async() => {

      const { data } = await Api.delete('deleteAttendanceItem', { id: item.value.id })

      dismiss()
    }

    onBeforeMount(() => {

    });

    onUpdated(() =>{
      item.value = props.itemParam
    });

    onUnmounted(() =>{

    });


    return { item, statusItem, saveItem, deleteItem, dismiss, message, triedToSave, brMask}
  }

})

