
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
  onBeforeMount,
  watch,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import EditItemModal from "./components/EditItemModal.vue";
import Button from "@/components/Button.vue"
import { ElNotification } from "element-plus";
import { Modal } from "bootstrap";
import Api from "@/services/Api";
import store from "@/store";
import ModalJSONIntegracao from "./components/ModalJSONIntegracao.vue";
import router from "@/router";
import useAlert from "@/composables/Alert";
import { useLoaderStore } from "@/store/LoaderStore";

interface attendanceType {
  placa: "";
  veiculo: "";
  marca: "";
  ano: "";
  km: "";
  fipe: "";
  cliente: "";
  documento: "";
  email: "";
  telefone: "";
  responsavel: "";
  status: "";
  valor: "";
  valorOriginal: "";
  valorDesconto: "";
  valorFinal: "";
  statusIntegracao: "";
  created_at: "";
  codConcessionaria?: any;
  observacao: "";
}

interface ItemType {
  codItemLoja: string;
  nome: string;
  status: string;
  valor: string;
  valorPeca: string;
  valorMo: string;
  categoria: string;
  codAgregados: string;
  campoCheckList: string;
  desconto: string;
  tipoItem: string;
  qtd: number | null;
  valorUnitario: string;
  valorOriginal: string;
}

interface getDownloadAtendimento {
  idOrcamento: number;
  idLoja: number;
  idUsuario: number;
}

export default defineComponent({
  name: "AttendanceDetails",

  components: {
    Field,
    Form,
    ErrorMessage,
    EditItemModal,
    ModalJSONIntegracao,
    Button
  },

  setup() {
    const auxModal: Ref<any> = ref(null);
    const { showTimeAlert } = useAlert();
    const loaderStore = useLoaderStore();

    // --------------------------------------------------
    const getUrlParam = (param: string) => new URLSearchParams(window.location.search).get(param);
    // const openModal = (id:string) => (new Modal(document.querySelector(`#${id}`))).show()
    const setEmptyItem = () => (item.value = emptyItem);
    const kmMask = (v: any) =>
      (attendance.value.km = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."));
    const codConcessionaria: Ref<any> = ref(null);
    const codGrupo:Ref<number | null> = ref(null);
    const cleanModal: Ref<boolean> = ref(false);

    // --------------------------------------------------
    const emptyItem: ItemType = {
      codItemLoja: "",
      nome: "",
      status: "",
      valor: "",
      valorPeca: "",
      valorMo: "",
      categoria: "",
      codAgregados: "",
      campoCheckList: "",
      desconto: "",
      tipoItem: "",
      qtd: null,
      valorUnitario: "",
      valorOriginal: "",
    };
    const emptyAttendance: attendanceType = {
      placa: "",
      veiculo: "",
      marca: "",
      ano: "",
      km: "",
      fipe: "",
      cliente: "",
      documento: "",
      email: "",
      telefone: "",
      responsavel: "",
      status: "",
      valor: "",
      valorOriginal: "",
      valorDesconto: "",
      valorFinal: "",
      statusIntegracao: "",
      created_at: "",
      observacao: ""
    };
    const statusAttendance: string[] = [
      "Aberto",
      "Enviado",
      "Fechado",
      "Vendido",
      "Cancelado",
      "Andamento",
      "Precificar",
      "Pendente Aprovação",
    ];
    const attendanceId: Ref<number | null> = ref(getUrlParam("id") ? Number(getUrlParam("id")) : null);
    const attendance: Ref<attendanceType> = ref(emptyAttendance);
    const itensArray: Ref<any[]> = ref([]);
    const item: Ref<ItemType> = ref(emptyItem);
    const loadTransmit: Ref<boolean> = ref(false);
    const loadSave: Ref<boolean> = ref(false);
    const store: Ref<any> = ref();
    const vehicleBrandArray: Ref<string[]> = ref([]);
    const ItemModal: Ref<any> = ref();
    const getDownloadAtendimento: Ref<getDownloadAtendimento> = ref({
      idOrcamento: 0,
      idLoja: 0,
      idUsuario: 0,
    });
    const jsonIntegracao: Ref<any> = ref(null)
    // --------------------------------------------------

    // --------------------------------------------------
    async function downloadAtendimento() {
      const { codConcessionaria } = attendance.value as any;

      const userString = localStorage.getItem("user");
      const idUsuario = userString ? JSON.parse(userString).idEncriptado : null;
      getDownloadAtendimento.value = {
        idOrcamento: attendanceId.value ? attendanceId.value : 0,
        idLoja: codConcessionaria.value,
        idUsuario: idUsuario,
      };

      const url = `${process.env.VUE_APP_URL_API}baixaOrcamentos/${getDownloadAtendimento.value.idOrcamento}/${codConcessionaria}/${getDownloadAtendimento.value.idUsuario}`;

      window.open(url, "_blank");
    }

    async function getAttendance() {

      const { data } = await Api.get("getAttendanceById", { id: attendanceId.value });
      attendance.value = data.atendimento;
      store.value = data.loja;
      codGrupo.value = data.loja.codGrupo;
    }

    async function getItens() {
      const { data } = await Api.get("getAttendanceItens", {
        id: attendanceId.value,
      });

      itensArray.value = data.itens;
    }

    async function getItem(id: number) {
      if (!id) return;

      const { data } = await Api.get("getItemById", { id: id });
      item.value = data.item;
    }

    async function getAllvehicleBrand() {

      const { data } = await Api.get("getAllvehicleBrand");
      vehicleBrandArray.value = data.marcas;

    }

    async function saveAttendance() {

      loadSave.value = true;

      const { data } = await Api.post("saveAttendance", { ...attendance.value });

      loadSave.value = false;

      if(!data.success) return showTimeAlert("Não foi possível salvar o atendimento", "error");

      await getAttendance();

      showTimeAlert("O atendimento foi salvo com sucesso!");

    }

    async function closeModal() {
      await getAttendance();
      ItemModal.value.hide();
    }

    async function transmit() {

      loadTransmit.value = true;

      try {

        const data = await Api.post("transmitAttendance", { id: attendanceId.value });
  
        loadTransmit.value = false;
  
        if (!data.success) return showTimeAlert(data.mensagem, "error");
  
        showTimeAlert(data.mensagem);

      } catch(error) { return showTimeAlert('Não foi possível transmitir o atendimento.', "error"); } finally { loadTransmit.value = false; showTimeAlert('Não foi possível transmitir o atendimento.', "error"); }
      
    }

    function openModal() {
      ItemModal.value.show();
    }



    const openModalJSON = async (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal.value = await new Modal(auxElement);
      auxModal.value.show();
    };

    async function getStatusAtendimentoIntegracao(atd: number, loja: number, tipo: string) {
      loaderStore.open()
      try {
        const res = await Api.post('getAtendimentoIntegracao', {tipoIntegracao : tipo,idLoja : loja, idAtendimento : atd, portal: <boolean>true})
        jsonIntegracao.value = res
        codConcessionaria.value = null
        await openModalJSON("ModalJSONIntegracao");
        jsonIntegracao.value && !jsonIntegracao.value.success  ? showTimeAlert('Atendimento não foi transmitido a integração', "error") : null;
      } catch (error) {
        console.log(error);
      } finally {
        loaderStore.close()
      }
    }

    async function buscaJson(id: number) {
      cleanModal.value = !cleanModal.value;
      codConcessionaria.value = id;
      jsonIntegracao.value = null
      await openModalJSON("ModalJSONIntegracao"); 
      codConcessionaria.value = null
    }

    onBeforeMount(async () => {

      await getAttendance();
      await getItens();
      await getAllvehicleBrand();
      ItemModal.value = new Modal(document.querySelector("#EditItemModal"));

    });

    return {
      store,
      attendance,
      attendanceId,
      itensArray,
      history,
      getItens,
      openModal,
      closeModal,
      getItem,
      item,
      setEmptyItem,
      vehicleBrandArray,
      statusAttendance,
      saveAttendance,
      loadSave,
      transmit,
      loadTransmit,
      console,
      openModalJSON,
      codConcessionaria,
      buscaJson,
      cleanModal,
      downloadAtendimento,
      getStatusAtendimentoIntegracao,
      jsonIntegracao,
      auxModal,
      loaderStore,
      codGrupo
    };
  },
});
